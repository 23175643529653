import React from 'react';
import { ViewTable, Layout, Button, Filter } from '@/components';
import { IFilterField } from '@/components/filter/Filter';
import WebApi from '@/web/api';
import LibEnum from 'lib/enum';
import { routerMap } from '@/router/agent_router';

interface IBookTableProps extends RouterPropsType {
  type: 'sale' | 'saleReturn' | 'purchase' | 'purchaseReturn' | 'fee';
}

export default class BookTable extends React.PureComponent<IBookTableProps> {

  private table = React.createRef<ViewTable>();

  constructor(props: IBookTableProps) {
    super(props);

    if (['sale', 'saleReturn'].includes(props.type)) {
      this.filterFields.unshift({
        type: 'customer',
        field: 'targetId'
      });
      if (props.type === 'sale') {
        this.bookTypeList = [LibEnum.BOOK_TYPE.SALE.value];
      } else {
        this.bookTypeList = [LibEnum.BOOK_TYPE.SALE_RETURN.value];
      }
      this.targetName = '客户';
    }

    if (['purchase', 'purchaseReturn'].includes(props.type)) {
      this.filterFields.unshift({
        type: 'supplier',
        field: 'targetId'
      });
      if (props.type === 'purchase') {
        this.bookTypeList = [LibEnum.BOOK_TYPE.PURCHASE.value];
      } else {
        this.bookTypeList = [LibEnum.BOOK_TYPE.PURCHASE_RETURN.value];
      }
      this.targetName = '供应商';
    }
  }

  private targetName = '收款单位';

  private queryParams: any = {}

  private bookTypeList: string[] = [];

  findAllReceived = () => {
    this.table.current?.reset(this.queryParams);
  }

  render() {
    
    return <Layout.Page>
      <Layout.Toolbar title={this.title}>
        <Button.Refresh onClick={() => {
          this.table.current?.refresh();
        }}/>
      </Layout.Toolbar>

      <Filter
        fields={this.filterFields}
        onChange={values => {
          this.queryParams = values;
          this.table.current?.reset()
        }}
      />

      <ViewTable
        flex1
        ref={this.table}
        rowKey={row => row.bookId}
        fetch={data => {
          return WebApi.book_paging({...data, bookTypeList: this.bookTypeList, ...this.queryParams})
        }}
        columns={[
          {
            title: '单号',
            align: 'center',
            width: 200,
            dataIndex: 'bookId',
          },
          {
            title: '交易时间',
            align: 'center',
            width: 200,
            dataIndex: 'datetime',
          },
          {
            title: this.targetName,
            width: 200,
            dataIndex: 'targetName',
          },
          {
            title: '交易金额',
            align: 'right',
            width: 120,
            dataIndex: 'actualAmount',
          },
          {
            title: '交易类型',
            align: 'center',
            dataIndex: 'bookTypeDesc',
            width: 120,
          },
          {
            title: '备注说明',
            width: 300,
            titleAlign: 'left',
            dataIndex: 'remark',
          },
        ]}
        actionButtons={[
          {
            type: 'detail',
            hidden: this.props.type === 'fee',
            onClick: (row: any) => {
              if (this.props.type === 'sale') {
                this.props.history.push(routerMap.sale_order_detail.getPath(row.relevanceId, '11'));
              }
              if (this.props.type === 'saleReturn') {
                this.props.history.push(routerMap.saleReturnOrderDetail.getPath(row.relevanceId));
              }
              if (this.props.type === 'purchase') {
                this.props.history.push(routerMap.purchaseOrderDetail.getPath(row.relevanceId));
              }
              if (this.props.type === 'saleReturn') {
                this.props.history.push(routerMap['purchaseReturn.view.detail'].getPath(row.relevanceId));
              }
            }
          }
        ]}
      />
    </Layout.Page>
  }

  private title = {
    sale: '订货收款单',
    saleReturn: '订货退货付款单',
    purchase: '进货付款单',
    purchaseReturn: '进货退货收款单',
  }[this.props.type]

  private filterFields: IFilterField[] = [
    {
      name: '交易日期',
      field: 'dateRange',
      type: 'dateRange'
    },
  ];
}